import {computed, Ref, ref, watch} from '@vue/composition-api';
import {Point} from '@/common/types/Point';
import {maxBy, minBy, sortBy} from 'lodash';
import {calculateAxisMinMaxValue} from '@/common/utils/graphs';
import {clone} from '@/common/utils/clone';
import {DataTableHeader} from 'vuetify';
import {useLinearRegression} from '@/tasks/composables/useLinearRegression';

export interface UseMcMasterLab3SlopeInterceptOptions<
  Labels extends Record<Exclude<string, 'slope' | 'intercept'> | number | symbol, string>,
  X extends keyof Labels,
  Y extends keyof Labels,
> {
  labels: Labels;
  x: X;
  y: Y;
  rows: Ref<Row<keyof Labels>[]>;
  isMarking: boolean;
  linearEquationY: string;
  linearEquationX: string;
  minY: number | null;
  maxY: number | null;
  minX: number | null;
  maxX: number | null;
}

export type Row<Keys extends string | number | symbol> = {
  [key in Keys]: null | number;
};

// FIXME: We cannot get better typing on inputs while using 'inputsDataProp'
export function useMcMasterLab3SlopeIntercept<
  Labels extends Record<Exclude<string, 'slope' | 'intercept'> | number | symbol, string>,
  X extends keyof Labels,
  Y extends keyof Labels,
>({
  labels,
  x,
  y,
  rows,
  isMarking,
  linearEquationY,
  linearEquationX,
  minY,
  maxY,
  minX,
  maxX,
}: UseMcMasterLab3SlopeInterceptOptions<Labels, X, Y>) {
  const convertToFloat = (value: string | number | null) => {
    if (value === null || typeof value === 'number') {
      return value;
    }

    if (value === '') {
      return null;
    }

    return parseFloat(value);
  };

  const numericRows = computed(() => {
    return rows.value.map((row) => {
      return {
        ...row,
        concB: convertToFloat(row['concB']),
        abs: convertToFloat(row['abs']),
      } as Row<keyof Labels>;
    });
  });

  const newRow = {} as Row<keyof Labels>;
  Object.keys(labels).map((label) => (newRow[label as keyof Row<keyof Labels>] = null));

  watch(
    () => numericRows.value,
    () => {
      const dataPoints = numericRows.value;
      if (!dataPoints || dataPoints.length === 0) {
        rows.value = [clone(newRow)] as Row<keyof Labels>[];
      }
    },
    {
      immediate: true,
    }
  );

  const colDefs: Ref<DataTableHeader[]> = ref([
    {
      text: 'Run',
      value: 'index',
      sortable: false,
    },
  ]);
  Object.entries(labels).map(([value, text]) => colDefs.value.push({text, value, sortable: false}));

  if (!isMarking) {
    colDefs.value.push({
      text: '',
      value: 'id',
      sortable: false,
    });
  }

  const equation: Ref<string> = computed(() => {
    const interceptValue = intercept.value ?? 0;
    const slopeValue = slope.value;

    const equationOperation = interceptValue !== 0 ? (interceptValue >= 0 ? '+' : '-') : '';

    let result = '';

    result += `$${linearEquationY} = `;

    if (slopeValue !== null) {
      result += `\\displaystyle{${slopeValue.toFixed(0)}}\\ `;
    }
    if (equationOperation) {
      result += equationOperation + (slopeValue ? ' ' : '');
    }

    if (interceptValue !== 0) {
      result += Math.abs(interceptValue).toFixed(4);
    }

    result += '\\ce{[FeSCN^{2+}]}$';

    return result;
  });

  const plotPoints: Ref<Point[]> = computed(() => {
    const points = numericRows.value
      // @ts-ignore -- TS2590: Expression produces a union type that is too complex to represent.
      ?.filter((point) => !!point[x] && !!point[y])
      .map((run: any) => {
        return {
          x: run[x],
          y: run[y],
        };
      });

    return sortBy(points, 'x');
  });

  const xValues = computed(() => plotPoints.value.map(({x}) => x));
  const yValues = computed(() => plotPoints.value.map(({y}) => y));
  const {slope, intercept} = useLinearRegression(xValues, yValues, ref(true));

  const linearRegression = computed(() => {
    return intercept.value !== null || slope.value !== null
      ? (x: number) => (intercept.value ?? 0) + (slope.value ?? 0) * x
      : null;
  });

  const regressionLine: Ref<Point[]> = computed(() => {
    const linearRegressionFunction = linearRegression.value;
    return linearRegressionFunction
      ? plotPoints.value.map(({x}) => {
          return {x, y: linearRegressionFunction(x)};
        })
      : [];
  });

  const minYAxis = computed(
    () => minY ?? calculateAxisMinMaxValue(minBy(regressionLine.value, 'y')?.y ?? -10, false)
  );

  const maxYAxis = computed(
    () => maxY ?? calculateAxisMinMaxValue(maxBy(numericRows.value, y)?.[y] ?? 5)
  );
  const minXAxis = computed(() => minX ?? 0);

  const maxXAxis = computed(
    () => maxX ?? calculateAxisMinMaxValue(maxBy(numericRows.value, x)?.[x] ?? 100)
  );

  const apexSeries = computed(() => {
    return [
      {
        name: labels[y],
        type: 'scatter',
        data: plotPoints.value,
      },
      {
        name: 'Line of Best Fit',
        type: 'line',
        data: (regressionLine.value ?? []).length >= 2 ? regressionLine.value : [],
      },
    ];
  });

  const apexOptions = computed(() => {
    return {
      chart: {
        fontFamily: 'serif',
        fontsize: '16px',
        height: 350,
      },
      colors: ['#8B0000', '#620101'],
      markers: {
        size: [7, 0],
      },
      legend: {
        show: false,
      },
      yaxis: {
        title: {
          text: labels[y],
          style: {
            fontSize: '18px',
            fontWeight: '600',
          },
        },
        type: 'numeric',
        min: minYAxis.value,
        max: maxYAxis.value,
        tickAmount: 10,
        decimalsInFloat: 3,
      },
      xaxis: {
        title: {
          style: {
            fontSize: '14px',
          },
          text: labels[x],
        },
        type: 'numeric',
        min: minXAxis.value,
        max: maxXAxis.value,
        tickAmount: 8,
        decimalsInFloat: 6,
      },
    };
  });

  return {
    colDefs,
    equation,
    plotPoints,
    linearRegression,
    regressionLine,
    apexSeries,
    apexOptions,
  };
}
