import {computed, ref, Ref, watchEffect} from '@vue/composition-api';

export function useLinearRegression(
  x: Ref<number[]>,
  y: Ref<number[]>,
  forceZeroIntercept?: Ref<boolean>
) {
  const slope = ref<number | null>(null);
  const intercept = ref<number | null>(null);

  watchEffect(() => {
    if (x.value.length <= 1 || y.value.length <= 1) {
      slope.value = null;
      intercept.value = null;
      return;
    }
    const {m, b} = runLinearRegression(x.value, y.value, forceZeroIntercept?.value);

    slope.value = m;
    intercept.value = b;
  });

  const predictFn = (x: number) =>
    slope.value === null && intercept.value === null
      ? null
      : (intercept.value ?? 0) + (slope.value ?? 0) * x;

  return {
    slope,
    intercept,
    predictFn,
  };
}

export function runLinearRegression(x: number[], y: number[], forceZeroIntercept = false) {
  if (x.length <= 1 || y.length <= 1) {
    throw new Error('There must be at least two (x,y) pairs to do a linear regression');
  }

  // calculate number points
  const n = x.length;

  // ensure both arrays of points are the same size
  if (n != y.length) {
    throw new Error('Number of elements in coordinate arrays do not match.');
  }

  // calculate sums
  const x_sum = x.reduce((a, b) => a + b, 0);
  const y_sum = y.reduce((a, b) => a + b, 0);

  let xx_sum = 0;
  let xy_sum = 0;

  for (let i = 0; i < n; i++) {
    xy_sum += x[i] * y[i];
    xx_sum += x[i] * x[i];
  }

  if (forceZeroIntercept) {
    // calculate slope
    const m = xy_sum / xx_sum;

    // return result
    return {m, b: 0};
  } else {
    // calculate slope
    const m = (n * xy_sum - x_sum * y_sum) / (n * xx_sum - x_sum * x_sum);

    // calculate intercept
    const b = (y_sum - m * x_sum) / n;

    // return result
    return {m, b};
  }
}
